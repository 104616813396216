<template>
    <div class="section derivatives-section dark-bg">
        <div class="container">
            <div class="left">
                <img src="../assets/images/derivatives-logo.svg" alt="" class="logo">
                <h1 class="title" v-html="this.data.dc_slogan"></h1>
                <p class="description">{{ this.data.dc_description }}</p>
                <button class="light" @click="gotoAbout()">{{ $t('header.aboutUs') }}</button>
            </div>
            <div class="right">
                <img :src="`${this.apiUrl}/tmp/${this.data.dc_image}`" alt="" class="image">
            </div>
        </div>
    </div>
</template>

<script>
import {apiGetHomeConfig} from '@/apis/copywriting.js';
export default {
    name: 'DerivativesSection',
    data(){
          return {
              apiUrl: process.env.VUE_APP_API_URL,
              data: {}
          }
    },
    methods: {
          gotoAbout(){
              window.location = "/about";
          },
          loadCopywriting(){
              apiGetHomeConfig().then(res => {
                  if(res.data.status){
                      this.data = res.data.data
                  }
              })
          }
    },
    mounted(){
          this.loadCopywriting();
    }
}
</script>