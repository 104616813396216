<template>
    <div class="not-found">
        <div class="not-found-section">
            <div class="container">
                <h1>404</h1>
                <p>{{ $t('header.pageNotFound') }}</p>
                <button class="light" @click="backToHome()">{{ $t('header.home') }}</button>
            </div>
        </div>
        <JoinusSection />
        <DerivativesSection />
    </div>
</template>

<script>
import JoinusSection from '@/components/JoinusSection';
import DerivativesSection from '@/components/DerivativesSection';

export default {
    name: 'NotFound',
    components: {
        JoinusSection,
        DerivativesSection
    },
    data() {
        return{
            data: {},
        }
    },
    methods: {
        backToHome(){
            window.location = '/';
        }
    },
    mounted() {
    },
}
</script>