<template>
    <div v-if="false" class="section joinus-section">
        <h2>{{ this.data.joinus_slogan }}</h2>
        <div class="container logos-container">
            <img v-for="(item, index) in this.data.joinus_images" :key="index" :src="`${apiUrl}/tmp/${item}`" alt="" class="logo">
        </div>
        <div class="join-area">
            <div class="container">
                <div class="step-container">
                    <div class="step">
                        <img src="../assets/images/join/regis.svg" alt="" class="icon">
                        <h4 class="title">註冊成為會員</h4>
                        <p class="description"></p>
                    </div>
                    <div class="step">
                        <img src="../assets/images/join/plan.svg" alt="" class="icon">
                        <h4 class="title">選擇適合您的方案</h4>
                        <p class="description">我們提供三種方案，依據您的投資需求向您收取合理費用</p>
                        <!-- <a href="#">檢視方案細節</a> -->
                    </div>
                    <div class="step">
                        <img src="../assets/images/join/setting.svg" alt="" class="icon">
                        <h4 class="title">開通您的智能機器人</h4>
                        <p class="description">我們提供智能放貸機器人和期現套利機器人，讓您依據風險/收益來評估最適合您的選項</p>
                    </div>
                </div>
                <h2>開始透過智能機器人獲利！</h2>
                <button class="light" @click="trynow()">馬上行動</button>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import Cookies from 'js-cookie'
import {apiGetHomeConfig} from '@/apis/copywriting.js';
export default {
  name: 'JoinusSection',
  data(){
      return {
          Cookies,
          apiUrl: process.env.VUE_APP_API_URL,
          data: {}
      }
  },
  methods: {
        trynow(){
            if(Cookies.get('dcbotLogin')){
                window.location = "/dashboard"
            }else{
                $('.login-modal').fadeIn(300).css('display', 'flex');
                $('.form-container').removeClass('active');
                $('.form-container.login').addClass('active');
                $('.toggle-container > h2').removeClass('active');
                $('.toggle-container > h2[data-target=login]').addClass('active');
            }
        },
        loadCopywriting(){
            apiGetHomeConfig().then(res => {
                if(res.data.status){
                    this.data = res.data.data
                }
            })
        }
  },
  mounted(){
        this.loadCopywriting();
  }
}
</script>